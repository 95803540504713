import {
  Balance,
  MoreVert,
  NotificationsActive,
  Payments,
} from "@mui/icons-material";
import { Button, Divider, IconButton } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import LoadingOverlay from "../../../components/LoadingOverlay/LoadingOverlay";
import MoreMenu from "../../../components/MenuMore/MoreMenu";
import { Erc20TokenInfo } from "../../../types";
import { formatNumberWithSeparator } from "../../../utils/number-utils";
import WidgetList from "../../dashboard/components/WidgetList";
import { selectCommodityExchangeTokens } from "../../settings/settings.selectors";
import {
  selectIsGetRwaContractBalancesPending,
  selectRwaContractBalances,
} from "../treasury.selectors";
import {
  RwaContractBalances as RwaContractBalancesType,
  RwaTokenBalance,
} from "../types/rwa-contract-balances.type";

const renderHeader = (commodityExchangeTokens: Erc20TokenInfo[]) => (
  <div className="flex justify-between items-end">
    <span className="py-1">RWA Token</span>
    <div className="flex">
      {commodityExchangeTokens?.map((token) => (
        <div
          key={token.symbol}
          className="w-[200px] flex flex-col items-center [&:not(:last-of-type)]:border-r"
        >
          <div className="pb-1 flex items-center gap-1 font-semibold">
            {token.imageUrl && <img className="w-6 h-6" src={token.imageUrl} />}
            {token.symbol}
          </div>
          <div className="w-full flex">
            <span className="flex-1 py-1 text-center border-r">Treasury</span>
            <span className="flex-1 py-1 text-center">Commission</span>
          </div>
        </div>
      ))}
    </div>
  </div>
);

const renderItem = (
  item: RwaTokenBalance,
  commodityExchangeTokens: Erc20TokenInfo[]
) => {
  return (
    <div className="flex justify-between items-center text-sm">
      <span>{item.rwaToken.symbol}</span>
      <div className="flex">
        {commodityExchangeTokens?.map((token) => (
          <div
            key={token.symbol}
            className="w-[200px] flex [&:not(:last-of-type)]:border-r"
          >
            <span className="flex-1 py-2 text-center border-r">
              {formatNumberWithSeparator(item[token.symbol].treasuryFormatted)}
            </span>
            <span className="flex-1 py-2 text-center">
              {formatNumberWithSeparator(
                item[token.symbol].commissionFormatted
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const renderSummary = (
  rwaContractBalances: RwaContractBalancesType,
  commodityExchangeTokens: Erc20TokenInfo[]
) => {
  return (
    <div className="flex flex-col text-sm">
      <div className="flex justify-between items-center border-b">
        <span className="opacity-60 text-xs uppercase">Virtual Balance</span>
        <div className="flex">
          {commodityExchangeTokens?.map((token) => (
            <div
              key={token.symbol}
              className="w-[200px] py-2 flex justify-center items-center gap-1 [&:not(:last-of-type)]:border-r"
            >
              {formatNumberWithSeparator(
                rwaContractBalances?.totalVirtualBalances[token.symbol].total
              )}{" "}
              {rwaContractBalances?.totalVirtualBalances[token.symbol].diff ? (
                <span className="text-xs text-red-600">
                  (
                  {rwaContractBalances?.totalVirtualBalances[token.symbol]
                    .diff > 0
                    ? "+"
                    : "-"}
                  {formatNumberWithSeparator(
                    Math.abs(
                      rwaContractBalances?.totalVirtualBalances[token.symbol]
                        .diff
                    )
                  )}
                  )
                </span>
              ) : null}
            </div>
          ))}
        </div>
      </div>

      <div className="flex justify-between items-center">
        <span className="opacity-60 text-xs uppercase">Actual Balance</span>
        <div className="flex">
          {commodityExchangeTokens?.map((token) => (
            <div
              key={token.symbol}
              className="w-[200px] py-2 text-center [&:not(:last-of-type)]:border-r"
            >
              {formatNumberWithSeparator(
                rwaContractBalances?.totalActualBalances[token.symbol]
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default function RwaContractBalances() {
  const rwaContractBalances = useSelector(selectRwaContractBalances);
  const isPending = useSelector(selectIsGetRwaContractBalancesPending);
  const commodityExchangeTokens = useSelector(selectCommodityExchangeTokens);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
    null
  );

  const showMenu = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setMenuAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  return (
    <LoadingOverlay loading={isPending}>
      <div className="w-full h-full p-widget">
        <div className="WidgetHeader  flex justify-between items-center">
          RWA Contract Balances
          <IconButton size="small" onClick={showMenu}>
            <MoreVert />
          </IconButton>
        </div>
        <div className="mt-4">
          <WidgetList
            items={rwaContractBalances?.rwaBalances}
            getKey={(item) => item.rwaToken.symbol}
            itemsContainerClassName="max-h-[176px] overflow-y-auto"
            headerClassName="p-0"
            itemClassName="p-0"
            renderHeader={() => renderHeader(commodityExchangeTokens)}
            renderItem={(item) => renderItem(item, commodityExchangeTokens)}
          />
          {rwaContractBalances?.rwaBalances &&
            renderSummary(rwaContractBalances, commodityExchangeTokens)}
        </div>
      </div>
      <MoreMenu anchorEl={menuAnchorEl} onClose={closeMenu}>
        <div className="flex flex-col px-1">
          <Button
            className="justify-start"
            variant="text"
            color="info"
            startIcon={<Balance />}
            onClick={() => {}}
          >
            Update Balance
          </Button>
          <Divider />
          <Button
            className="justify-start"
            variant="text"
            color="darkGold"
            startIcon={<Payments />}
            onClick={() => {}}
          >
            Deposit Token
          </Button>
          <Divider />
          <Button
            className="justify-start"
            variant="text"
            color="warning"
            startIcon={<NotificationsActive />}
            onClick={() => {}}
          >
            Balance Notifier
          </Button>
        </div>
      </MoreMenu>
    </LoadingOverlay>
  );
}
