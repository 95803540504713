import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffectOnce } from "usehooks-ts";
import WalletConnectorProvider from "../../../providers/WalletConnectorProvider";
import { AppPage, AppRefreshStatus } from "../../../types";
import {
  selectRefreshPage,
  selectRefreshStatus,
} from "../../app/app.selectors";
import { appActions } from "../../app/app.state";
import {
  getAccountBalancesActionsAction,
  getRwaContractBalancesAction,
} from "../treasury.effects";
import {
  selectIsGetAccountBalancesPending,
  selectIsGetRwaContractBalancesPending,
} from "../treasury.selectors";
import AccountBalances from "./AccountBalances";
import RwaContractBalances from "./RwaContractBalances";
import "./Treasury.scss";

export default function Treasury() {
  const refreshPage = useSelector(selectRefreshPage);
  const refreshStatus = useSelector(selectRefreshStatus);
  const isGetAccountBalancesPending = useSelector(
    selectIsGetAccountBalancesPending
  );
  const isGetRwaContractBalancesPending = useSelector(
    selectIsGetRwaContractBalancesPending
  );
  const dispatch = useDispatch();

  const getTreasuryData = () => {
    dispatch(getAccountBalancesActionsAction());
    dispatch(getRwaContractBalancesAction());
  };

  useEffect(() => {
    if (
      refreshStatus === AppRefreshStatus.Invalidated &&
      refreshPage === AppPage.Treasury
    ) {
      getTreasuryData();
    }
  }, [refreshStatus]);

  useEffect(() => {
    let status = AppRefreshStatus.Idle;
    if (isGetAccountBalancesPending || isGetRwaContractBalancesPending) {
      status = AppRefreshStatus.InProgress;
    }
    if (refreshStatus !== status) {
      dispatch(
        appActions.changeRefresh({ page: AppPage.Treasury, status: status })
      );
    }
  }, [
    isGetAccountBalancesPending,
    isGetRwaContractBalancesPending,
    refreshStatus,
  ]);

  useEffectOnce(() => {
    getTreasuryData();
  });

  return (
    <WalletConnectorProvider>
      <div className="TreasuryManagement">
        <div className="Widget">
          <RwaContractBalances />
        </div>

        <div className="Widget">
          <AccountBalances />
        </div>
      </div>
    </WalletConnectorProvider>
  );
}
