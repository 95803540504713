import { Divider, ListItemIcon, Tooltip } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { CSSObject, styled, Theme } from "@mui/material/styles";
import classNames from "classnames";
import * as React from "react";
import { NavLink } from "react-router-dom";
import { Menu, MenuItem, SettingsMenu } from "../../common/menu";
import { useSelector } from "react-redux";
import { selectCompaniesUnderReviewCount } from "../../modules/companies/companies.selectors";
import { selectOffersUnderViewCount } from "../../modules/offers/offers.selectors";
import { selectRequestsUnderViewCount } from "../../modules/requests/requests.selectors";
import { selectGetPlanUpgradeRequests } from "../../modules/plans/plans.selectors";
import { selectSmartDealsPromptPayUnderReviewCount } from "../../modules/blockchain/blockchain.selectors";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const renderMenuItem = (
  item: MenuItem,
  open: boolean,
  badges?: {
    Companies: number;
    Offers: number;
    Requests: number;
    Plans: number;
    "Smart Deals": number;
  }
) => (
  <NavLink
    to={item.link}
    key={item.link}
    className={({ isActive }) =>
      classNames(
        "flex border-l-2 border-solid",
        { "border-l-secondary": isActive },
        { "bg-gray-300": isActive }
      )
    }
  >
    <Tooltip title={open ? "" : item.label} placement="right">
      <ListItemButton
        sx={{
          minHeight: 42,
          justifyContent: open ? "initial" : "center",
          px: 2.5,
          py: 0,
        }}
      >
        <ListItemIcon
          className="relative"
          sx={{
            minWidth: 0,
            mr: open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {item.renderIcon()}
          {item.label in (badges || {}) && badges[item.label] ? (
            <div className="absolute -left-1.5 -top-1.5 w-5 h-5 rounded-full flex items-center justify-center bg-orange-400 text-white text-xs font-semibold">
              {badges[item.label]}
            </div>
          ) : null}
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{
            className: "font-lato",
          }}
          primary={item.label}
          sx={{ opacity: open ? 1 : 0 }}
        ></ListItemText>
      </ListItemButton>
    </Tooltip>
  </NavLink>
);

export default function MainLayoutDrawer({ open }) {
  const companiesUnderReviewCount = useSelector(
    selectCompaniesUnderReviewCount
  );
  const offersUnderReviewCount = useSelector(selectOffersUnderViewCount);
  const requestsUnderReviewCount = useSelector(selectRequestsUnderViewCount);
  const planUpgradeRequestsCount = useSelector(
    selectGetPlanUpgradeRequests
  ).length;
  const smartDealsPromptPayUnderReviewCount = useSelector(
    selectSmartDealsPromptPayUnderReviewCount
  );

  return (
    <Drawer variant="permanent" open={open}>
      <List className="h-full mt-16 flex flex-col">
        {Menu.map((menu, index) => (
          <React.Fragment key={index}>
            {menu.map((subMenu) =>
              renderMenuItem(subMenu, open, {
                Companies: companiesUnderReviewCount,
                Offers: offersUnderReviewCount,
                Requests: requestsUnderReviewCount,
                Plans: planUpgradeRequestsCount,
                "Smart Deals": smartDealsPromptPayUnderReviewCount,
              })
            )}
            {index < Menu.length - 1 && <Divider />}
          </React.Fragment>
        ))}
        <div className="mt-auto">{renderMenuItem(SettingsMenu, open)}</div>
      </List>
    </Drawer>
  );
}
