import { createAsyncThunk } from "@reduxjs/toolkit";
import { treasuryApi } from "../../http";

export const getAccountBalancesActionsAction = createAsyncThunk(
    "treasury/getAccountBalances",
    async () => {
        return treasuryApi.getAccountBalances();
    }
)

export const getRwaContractBalancesAction = createAsyncThunk(
    "treasury/getRwaContractBalances",
    async () => {
        return treasuryApi.getRwaContractBalances();
    }
)