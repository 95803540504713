import classNames from "classnames";

interface IProps<T> {
  items: T[];
  getKey?: (item: T) => any;
  renderHeader: () => JSX.Element;
  renderItem: (item: T) => JSX.Element;
  renderEmpty?: () => JSX.Element;
  itemsContainerClassName?: string;
  headerClassName?: string;
  itemClassName?: string;
}

export default function WidgetList<T>({
  items,
  getKey = (item: T) => (item as any).id,
  renderHeader,
  renderItem,
  renderEmpty,
  itemsContainerClassName,
  headerClassName,
  itemClassName,
}: IProps<T>) {
  return (
    <div className="WidgetList">
      {items?.length ? (
        <>
          <div className={classNames("header", headerClassName)}>
            {renderHeader()}
          </div>
          <div className={itemsContainerClassName}>
            {items.map((item) => (
              <div
                key={getKey?.(item) ?? item}
                className={classNames("row", itemClassName)}
              >
                {renderItem(item)}
              </div>
            ))}
          </div>
        </>
      ) : (
        renderEmpty?.()
      )}
    </div>
  );
}
