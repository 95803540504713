import { useEffect, useMemo, useState } from "react";
import { useDebounce } from "usehooks-ts";
import SplitButton from "../../../../components/SplitButton/SplitButton";
import Timeline from "../../../../components/Timeline/Timeline";
import { useModal } from "../../../../providers/ModalProvider";
import { useWs } from "../../../../providers/WsProvider";
import { BlockchainNotificationType } from "../../../../types";
import {
  SmartDealPaymentActionText,
  getExecutablePaymentActionTypes,
} from "../../../../utils/coimex-utils";
import { fromNow } from "../../../../utils/dt-utils";
import {
  BlockchainTxUpdatedNotification,
  ProformaInvoice,
  SmartDealPayment,
  SmartDealPaymentAction,
  SmartDealPaymentActionType,
  SmartDealPaymentBlockchainInfo,
} from "../../types";
import BlockchainFlowItem from "../BlockchainFlowItem";
import BlockchainFlowTx from "../BlockchainFlowTx";
import SmartDealPaymentFlowClose from "./SmartDealPaymentFlowClose";
import SmartDealPaymentFlowConfirm from "./SmartDealPaymentFlowConfirm";

interface IProps {
  proformaInvoice: ProformaInvoice;
  payment: SmartDealPayment;
  paymentBlockchainInfo: SmartDealPaymentBlockchainInfo;
  disabled?: boolean;
  disabledReason?: string;
  onUpdate: (update: Partial<SmartDealPayment>) => void;
  onInvalidate: () => void;
}

export default function SmartDealPaymentFlow({
  proformaInvoice,
  payment,
  paymentBlockchainInfo,
  disabled,
  disabledReason,
  onUpdate,
  onInvalidate,
}: IProps) {
  const executableActionTypes = useMemo(
    () => getExecutablePaymentActionTypes(paymentBlockchainInfo),
    [paymentBlockchainInfo]
  );
  const options = useMemo(
    () => executableActionTypes.map((type) => SmartDealPaymentActionText[type]),
    [executableActionTypes]
  );
  const paymentActions = useMemo(
    () => payment.paymentActions?.sortBy("createdAt", "asc"),
    [payment]
  );
  const { onBlockchainEvent } = useWs();
  const [invalidatePaymentObj, setInvalidatePaymentObj] = useState<object>();
  const debouncedInvalidatePayment = useDebounce(invalidatePaymentObj, 2000);
  const openModal = useModal();

  const handleActionClick = async (type: SmartDealPaymentActionType) => {
    const paymentAction: SmartDealPaymentAction = await openModal((props) => (
      <SmartDealPaymentFlowConfirm
        id={payment.id}
        type={type}
        proformaInvoice={proformaInvoice}
        paymentInfo={paymentBlockchainInfo}
        {...props}
      />
    ));
    if (paymentAction) {
      onUpdate({
        paymentActions: [...(payment.paymentActions || []), paymentAction],
      });
    }
  };

  const handleBLockchainTxUpdatedNotification = (
    notification: BlockchainTxUpdatedNotification
  ) => {
    if (
      payment.createPaymentTx.txHash == notification.txHash ||
      payment.paymentActions?.some(
        (action) => action.tx.txHash == notification.txHash
      )
    ) {
      setInvalidatePaymentObj({});
    }
  };

  useEffect(() => {
    if (debouncedInvalidatePayment) {
      onInvalidate();
    }
  }, [debouncedInvalidatePayment]);

  useEffect(() => {
    onBlockchainEvent?.on(
      BlockchainNotificationType.TxUpdated,
      handleBLockchainTxUpdatedNotification
    );

    return () => {
      onBlockchainEvent?.off(
        BlockchainNotificationType.TxUpdated,
        handleBLockchainTxUpdatedNotification
      );
    };
  }, [onBlockchainEvent]);

  return (
    <div className="SmartDealPaymentFlow">
      <Timeline position="right">
        <BlockchainFlowItem
          className="mb-2"
          title="Payment Created"
          timestamp={fromNow(payment.createPaymentTx.createdAt)}
          status={payment.createPaymentTx.status}
        >
          <BlockchainFlowTx tx={payment.createPaymentTx} />
        </BlockchainFlowItem>

        {payment.paymentActions?.sortBy("createdAt", "asc").map(({ tx }) => (
          <BlockchainFlowItem
            key={tx.txHash}
            className="mb-2"
            title={SmartDealPaymentActionText[tx.type]}
            timestamp={fromNow(tx?.createdAt)}
            status={tx?.status}
          >
            <BlockchainFlowTx tx={tx} />
          </BlockchainFlowItem>
        ))}

        {options?.length ? (
          <BlockchainFlowItem renderTitle={() => disabled && disabledReason}>
            <SplitButton
              options={options}
              disabled={disabled}
              onClick={(idx) => handleActionClick(executableActionTypes[idx])}
            />
          </BlockchainFlowItem>
        ) : (
          paymentBlockchainInfo?.isClose && (
            <SmartDealPaymentFlowClose
              timestamp={fromNow(
                paymentActions?.[paymentActions.length - 1].createdAt
              )}
            />
          )
        )}
      </Timeline>
    </div>
  );
}
