import api from ".";
import { AccountBalanceNotifier } from "../modules/treasury/types/account-balance-notifier.entity";
import { RwaContractBalances } from "../modules/treasury/types/rwa-contract-balances.type";
import { SaveAccountBalanceNotifierRequest } from "../modules/treasury/types/save-account-balance-notifier-request.type";
import { TreasuryAccountBalance } from "../types";

export const treasuryApi = {
    async getAccountBalances(): Promise<TreasuryAccountBalance[]> {
        return (await api.get('/treasury-management/account-balances')).data;
    },
    async saveAccountBalance(dto: SaveAccountBalanceNotifierRequest): Promise<AccountBalanceNotifier> {
        return (await api.post('/treasury-management/account-balances', dto)).data;
    },
    deleteAccountBalance(id: TreasuryAccountBalance['id']): Promise<void> {
        return api.delete(`/treasury-management/account-balances/${id}`);
    },
    async getRwaContractBalances(): Promise<RwaContractBalances> {
        return (await api.get('/treasury-management/rwa-contract-balances')).data;
    }
}