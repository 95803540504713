import { createSlice } from "@reduxjs/toolkit";
import { TreasuryAccountBalance } from "../../types";
import { addCommonReducers } from "../../utils/store-utils";
import { getAccountBalancesActionsAction, getRwaContractBalancesAction } from "./treasury.effects";
import { RwaContractBalances } from "./types/rwa-contract-balances.type";

export const TREASURY_STATE_NAME = 'treasury';

export interface TreasuryState {
    accountBalances: TreasuryAccountBalance[],
    rwaContractBalances: RwaContractBalances
}

export const treasuryState = createSlice({
    name: TREASURY_STATE_NAME,
    initialState: {
        accountBalances: []
    } as TreasuryState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAccountBalancesActionsAction.fulfilled, (state, action) => {
                state.accountBalances = action.payload;
            })
            .addCase(getRwaContractBalancesAction.fulfilled, (state, action) => {
                state.rwaContractBalances = action.payload;
            });
        addCommonReducers(builder, TREASURY_STATE_NAME);
    }
});

export const treasuryActions = treasuryState.actions;
export const treasuryReducer = treasuryState.reducer;