import { Provider, ZeroAddress, getAddress } from "ethers";
import { BlockchainEventType, BlockchainTx } from "../modules/blockchain/types";

export function toShortHash(hash: string, visible = 6): string {
  return hash
    ? `${hash.substring(0, visible)}...${hash.slice(-1 * visible)}`
    : "";
}

export function isValidBlockchainAddress(address: string): boolean {
  try {
    getAddress(address);
    return true;
  } catch {
    return false;
  }
}

export function isBlockchainAddressZero(address: string) {
  return address === ZeroAddress;
}

export function toDecimalsAmount(
  amount: number | bigint,
  decimals: number | bigint
): number {
  return Number(amount) * Math.pow(10, Number(decimals));
}

export function fromDecimalsAmount(
  amount: number | bigint,
  decimals: number | bigint
): number {
  return +(Number(amount) / Math.pow(10, Number(decimals))).toFixed(3);
}

export function hasBlockChainTxEventOfType(
  tx: BlockchainTx,
  type: BlockchainEventType
): boolean {
  return tx?.events?.some((e) => e.type === type);
}

export function isInClosingState(provider: Provider) {
  return false;
}

export function toBlockchainTxUrl(explorerUrl: string, txHash: string): string {
  return explorerUrl.replace("{txHash}", txHash);
}

export function toBlockchainAddressUrl(
  explorerUrl: string,
  address: string
): string {
  return explorerUrl.replace("{address}", address);
}

export function toIntAndDecimals(x: number): {
  n: number;
  d: number;
} {
  let nStr = x.toString();
  let decimalPos = nStr.indexOf('.');

  if (decimalPos === -1) {
    return { n: x, d: 0 };
  }

  let d = nStr.length - decimalPos - 1;
  let n = parseInt(nStr.replace('.', ''));

  return { n, d };
}

export function toBlockchainPercent(percent: number): {
  n: number;
  d: number;
} {
  const { n, d } = toIntAndDecimals(percent);
  return { n, d: d + 2 };
}